import React from "react";
import { Container, CardBody, Col, Row, Card } from "reactstrap";

import Image1 from "../assets/images/logo-gss.jpg";
import Image2 from "../assets/images/client/02.jpg";
import Image3 from "../assets/images/client/03.jpg";
import Image4 from "../assets/images/client/04.jpg";

/**
 * Agency team section
 */
export default function AgencyTeam() {
  const team = [
    {
      name: "Cristino Murphy",
      profile: Image1,
      department: "Management",
    },
    {
      name: "Leosy Clony",
      profile: Image1,
      department: "Management",
    },
    {
      name: "Amanda Lair",
      profile: Image1,
      department: "Management",
    },
    {
      name: "Calvin Carlo",
      profile: Image1,
      department: "Management",
    },
  ];

  return (
    <>
      <section className="section overflow-hidden bg-light" id="team">
        <Container>
          <Row className="justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">GSS Distribution Map</h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  Temukan lokasi Sub Stockist, Stockist dan Master Stocking
                  terdekat di wilayah Anda
                </p>
              </div>
            </div>
          </Row>

          {/* <Row>
            {team.map((item, key) => (
              <Col lg={3} md={6} className="mt-4 pt-2" key={key}>
                <Card className="border-0 text-center shadow border-0 overflow-hidden rounded">
                  <img src={item.profile} className="img-fluid" alt="" />
                  <CardBody>
                    <h5 className="mb-1">{item.name}</h5>
                    <small className="text-muted">{item.department}</small>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16328182.993487356!2d107.21865214552204!3d-2.381330454439812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e0!3m2!1sid!2sid!4v1721275578027!5m2!1sid!2sid"
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-100"
            />
          </div>
        </Container>
      </section>
    </>
  );
}
