import React, { useState } from "react";
import { Col, Container, Card, Row, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import Blo1Image from "../assets/images/logo-gss.jpg";
import Blo2Image from "../assets/images/blog/2.jpg";
import Blo3Image from "../assets/images/blog/3.jpg";

export default function News() {
  const [numColumns, setNumColumns] = useState(2);
  const staticData = [
    "https://www.youtube.com/embed/9QBzrU-eX-o?si=nuzRIw_Nd9c0M7-N",
    "https://www.youtube.com/embed/rAl-J0C95g4?si=7L_vpDnCrjUWW6-E",
    "https://www.youtube.com/embed/ddJN50KmOTk?si=czPQgQqrmeg9OA8B",
    "https://www.youtube.com/embed/FRxx4qzGxXw?si=0M3N45PE1qOSLY84",
  ];
  const maxColumns = staticData.length;

  const renderColumns = () => {
    const columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push(
        <Col md={6} className="mt-4 pt-2" key={i}>
          <Card className="blog blog-primary shadow rounded overflow-hidden border-0">
            <iframe
              height="300"
              src={staticData[i]} // Use the static data URLs
              title={`YouTube video ${i + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="w-100"
            ></iframe>
          </Card>
        </Col>
      );
    }
    return columns;
  };

  const handleButtonClick = () => {
    const remainingColumns = maxColumns - numColumns;
    const increment = Math.min(remainingColumns, 2); // Increment by 2 or less if remaining columns are fewer
    setNumColumns(numColumns + increment);
  };

  return (
    <>
      {/* Start Blog  */}
      <section className="section bg-light" id="news">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">Latest News</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  Temukan berita dan informasi terkini dari PT Global Star
                  Success
                </p>
              </div>
            </Col>
          </Row>

          <Row>{renderColumns()}</Row>

          {numColumns < maxColumns && (
            <div className="text-center mt-5">
              <button className="btn btn-primary" onClick={handleButtonClick}>
                Lihat lebih
              </button>
            </div>
          )}
        </Container>
      </section>
      {/* End Blog  */}
    </>
  );
}
