import React, { useState } from "react";
import * as Icon from "react-feather";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

const categories = ["All", "Soft Launching GSS", "NDT GSS", "OPP GSS"];
const staticData = [
  { src: "/gallery/foto-1.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-2.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-3.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-4.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-5.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-6.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-7.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-8.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-9.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/foto-10.jpg", category: "Soft Launching GSS" },
  { src: "/gallery/ndt-gss/foto (1).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (2).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (3).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (4).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (5).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (6).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (7).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (8).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (9).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (10).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (11).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (12).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (13).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (14).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (15).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (16).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (17).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (18).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (19).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (20).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (21).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (22).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (23).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (24).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (25).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (26).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (27).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (28).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (29).jpg", category: "NDT GSS" },
  { src: "/gallery/ndt-gss/foto (30).jpg", category: "NDT GSS" },
  { src: "/gallery/opp-gss/foto (1).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (2).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (3).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (4).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (5).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (6).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (7).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (8).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (9).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (10).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (11).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (12).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (13).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (14).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (15).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (16).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (17).jpg", category: "OPP GSS" },
  { src: "/gallery/opp-gss/foto (18).jpg", category: "OPP GSS" },
];

export default function AgencyProject() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [numColumns, setNumColumns] = useState(4);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredData =
    selectedCategory === "All"
      ? staticData
      : staticData.filter((item) => item.category === selectedCategory);

  const maxColumns = filteredData.length;

  const renderColumns = () => {
    const columns = [];
    for (let i = 0; i < numColumns; i++) {
      if (i >= filteredData.length) break;
      columns.push(
        <Col lg={3} md={6} className="col-12 mt-4 pt-2" key={i}>
          <Card className="border-0 project project-primary position-relative d-block overflow-hidden rounded">
            <CardBody className="p-0">
              <Link
                to="#"
                onClick={() => {
                  setPhotoIndex(i);
                  setIsOpen(true);
                }}
              >
                <figure className="fig-gal mb-0">
                  <img
                    src={filteredData[i].src}
                    className="h-100 w-100"
                    alt=""
                  />
                </figure>
                <div className="overlay-work bg-dark"></div>
              </Link>
              <div className="icons text-center">
                <Link
                  to="#"
                  onClick={() => {
                    setPhotoIndex(i);
                    setIsOpen(true);
                  }}
                  className="btn btn-icon btn-pills lightbox"
                >
                  <Icon.Camera className="fea icon-sm image-icon" />
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }
    return columns;
  };

  const handleButtonClick = () => {
    const remainingColumns = maxColumns - numColumns;
    const increment = Math.min(remainingColumns, 4); // Increment by 4 or less if remaining columns are fewer
    setNumColumns(numColumns + increment);
  };

  return (
    <>
      {/* Project Start  */}
      <section className="section" id="gallery">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">Our Gallery</h4>
                <p className="text-muted mx-auto para-desc mb-0">
                  Beragam kegiatan dan aktifitas yang telah kami lakukan bersama
                  di PT Global Star Success
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="text-center">
              {categories.map((category, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setSelectedCategory(category);
                    setNumColumns(4);
                  }}
                  className={`m-1 btn ${
                    selectedCategory === category
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                >
                  {category}
                </button>
              ))}
            </Col>
          </Row>

          {filteredData.length > 0 ? (
            <Row>{renderColumns()}</Row>
          ) : (
            <Row>
              <Col className="text-center">
                <p>No photos available in this category.</p>
              </Col>
            </Row>
          )}
          {numColumns < maxColumns && filteredData.length > 0 && (
            <div className="text-center mt-5">
              <button className="btn btn-primary" onClick={handleButtonClick}>
                Lihat lebih
              </button>
            </div>
          )}
          <>
            {isOpen && (
              <Lightbox
                mainSrc={filteredData[photoIndex].src}
                nextSrc={
                  filteredData[(photoIndex + 1) % filteredData.length].src
                }
                prevSrc={
                  filteredData[
                    (photoIndex + filteredData.length - 1) % filteredData.length
                  ].src
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + filteredData.length - 1) % filteredData.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % filteredData.length)
                }
              />
            )}
          </>
        </Container>
      </section>
      {/* Project End  */}
    </>
  );
}
